import * as React from "react"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const QPage = () => {

    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }
    useEffect(() => {
        Axios.get("/log.php?page=question_"+lang);
    },[])

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu lang={lang} changeLang={changeLang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>Spørsmål</h1>
                    <p>
                        Vi har garantert ikke klart å besvare alle spørsmål
                        dere måtte ha på denne nettsiden - så dere er hjertelig
                        velkommen til å spørre oss direkte.
                    </p>

                    <p>
                        Det er bare å ringe eller sende melding til Knut (<nobr>+47 970 82 270</nobr>) eller Nina (<nobr>+47 952 54 715</nobr>)
                        når som helst om
                        hva det enn skulle være.
                    </p>

                </>
            }
            {lang == "en" &&
                <>
                    <h1>Questions</h1>
                    <p>
                        We have surely not covered absolutely all topics on this website - so you are more than welcome
                        to ask us any question you might have directly.
                    </p>

                    <p>
                        Feel free or message Knut (<nobr>+47 970 82 270</nobr>) or Nina (<nobr>+47 952 54 715</nobr>)
                        any time regarding any matter.
                    </p>
                </>
            }

        </main>
    )
}

export default QPage
